<template>
  <el-card>
    <div class="notice">
      <h3>{{ noticeTitle }}</h3>
      <div class="clock">
        <i class="el-icon-alarm-clock"></i>
        <span> {{ time }}</span>
      </div>
      <div v-html="content" class="ann-content"></div>
      <div class="page">
        <div class="page-left">
          <div class="page-left-tags">Tags:</div>
          <div class="page-label">
            <el-button size="mini" type="primary">{{ tag }}</el-button>
          </div>
        </div>
        <div class="page-right">
          <div class="page-right-tags">Views:</div>
          <div class="page-label">
            <i class="el-icon-view"></i>
            <span> {{ readingQuantity }}</span>
          </div>
        </div>
      </div>
    </div>
  </el-card>
</template>
<script>
import { getAnnouncementDetails } from '@/service/moudules/platformApi';
import { getCookie } from '@/util/userLoginFn';
import { mapActions } from 'vuex';
export default {
  data() {
    return {
      getCookie,
      getAnnouncementDetails,
      content: '',
      noticeTitle: '',
      time: '',
      tag: '',
      readingQuantity: 0,
    };
  },
  mounted() {
    this.getAnnounmentDetails();
  },
  methods: {
    ...mapActions('individualcenter', ['getListUnreadFn']),
    async getAnnounmentDetails() {
      try {
        let { id } = this.$route.query;

        let params = {
          id,
          sessionId: this.getCookie('sessionId'),
        };

        let { data, msg, code } = await getAnnouncementDetails(params);

        if (code !== 200) {
          this.$message({
            type: 'error',
            message: msg,
          });
          return false;
        }
        this.$message({
          type: 'success',
          message: '获取公告详情成功',
        });

        this.content = data === null ? '' : data.text;
        this.noticeTitle = data === null ? '' : data.title;
        this.time = data === null ? '' : data.createdDatetime;
        this.tag = data === null ? '' : data.tag ? data.tag : '空';
        this.readingQuantity = data === null ? 0 : data.readingQuantity;
        this.getListUnreadFn()
        // 如果阅读数量为1那么就是第一次阅读
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
<style lang="less">
.notice {
  padding: 30px;
  .clock {
    display: flex;
    justify-content: flex-end;
    & i {
      margin-right: 10px;
    }
  }
  .ann-content {
    border-bottom: 1px solid #eeeeee;
  }
  .page {
    display: flex;
    justify-content: space-between;
    .page-left {
      margin: 30px 15px;
      padding: 15px;
      .page-left-tags {
        margin-bottom: 10px;
      }
    }
    .page-right {
      margin: 30px 15px;
      padding: 15px;
      .page-right-tags {
        margin-bottom: 10px;
      }
    }
  }
}
</style>
